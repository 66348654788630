.loginPage ion-toolbar{

        --border-style: none;
        --border-color: transparent;
        --padding-top: 1rem;
        --padding-bottom: 1rem;
        --padding-start: 1rem;
        --padding-end: 1rem;
}

.headingText h5{

        margin-top: 0.2rem;
        color: #00BCD4;
        align-items: center;
        justify-content:center;
        border: 0px;
}

.center-content {
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    margin: auto;
}

.avatars img{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.center {
    margin: auto;
  display: block;
  }